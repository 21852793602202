import echarts from "echarts"
/**环形图, border:radius那种样式 */
function buildPieChart({ getvalue = [88], subtext = '匹配度', ...other }) {
  let option = {
    title: {
      text: getvalue + '%',
      textStyle: {
        color: '#4B56F2',
        fontSize: 20
      },
      subtext: subtext,
      subtextStyle: {
        color: '#666666',
        fontSize: 20
      },
      itemGap: 20,
      left: 'center',
      top: '43%'
    },
    tooltip: {
      // formatter: function (params) {
      //   return '<span style="color: #fff;">综合得分：'+ optionObj.getvalue + '分</span>';
      // }
    },
    angleAxis: { // 极坐标系的角度轴
      max: 100,
      clockwise: true, // 逆时针
      // 隐藏刻度线
      show: false
    },
    radiusAxis: { // 极坐标系的径向轴
      type: 'category',
      show: true,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false
      },
    },
    polar: {
      center: ['50%', '50%'],
      radius: '140%' //图形大小
    },
    series: [{
      type: 'bar',
      data: getvalue,
      showBackground: true,
      backgroundStyle: {
        color: '#E0E0E0' // 环形图灰色部分（环形图1-value部分）
      },
      coordinateSystem: 'polar',
      roundCap: true,
      barWidth: 12,
      itemStyle: {
        normal: {
          opacity: 1,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            // {
            //   offset: 0,
            //   color: '#5048F1'
            // },
            // {
            //   offset: 1,
            //   color: '#6A80FA'
            // }
            {
              offset: 0,
              color: '#5BAEF8'
            },
            {
              offset: 0.33,
              color: '#7A34EB'
            },
            {
              offset: 0.66,
              color: '#9D6AFC'
            },
            {
              offset: 1,
              color: '#4B56F2'
            }
          ]),
          // shadowBlur: 4,
          // shadowColor: '#000',
          // shadowOpacity: 0.8
        }
      }
    }]
  }
  return option
}
// 环形图（治理报告）
function buildReportPieChart(getvalue, subtext) {
  let option = {
    title: {
      // text: getvalue + '分',
      text: '{a|'+ getvalue +'}{c|分}',
      subtext: subtext,
      itemGap: 5,
      // left: 'center',
      // top: 'center',
      x: 'center',
      y: 'center',
      textStyle: {
        rich:{
          a: {
            fontSize: 24,
            color: '#4B56F2',
            // padding: [100, 0, 0, 0],
            position: 'absolute'
            // verticalAlign: 'text-bottom'
          },
          b: {
            fontSize: 40,
            color: '#000'
          },
          c: {
            fontSize: 12,
            color: '#666',
            // verticalAlign: 'text-bottom'
          }
        }
      },
      subtextStyle: {
        color: '#666666',
        fontSize: '1em',
      },
      // top: '50%'
    },
    tooltip: {
      show: false
    },
    angleAxis: { // 极坐标系的角度轴
      max: 100,
      clockwise: true, // 逆时针
      // 隐藏刻度线
      show: false
    },
    radiusAxis: { // 极坐标系的径向轴
      type: 'category',
      show: true,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false
      },
    },
    polar: {
      center: ['50%', '50%'],
      radius: '180%' //图形大小
    },
    series: [{
      type: 'bar',
      data: getvalue,
      showBackground: true,
      backgroundStyle: {
        color: '#E0E0E0' // 环形图灰色部分（环形图1-value部分）
      },
      coordinateSystem: 'polar',
      roundCap: true,
      barWidth: 12,
      itemStyle: {
        normal: {
          opacity: 1,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            // {
            //   offset: 0,
            //   color: '#5048F1'
            // },
            // {
            //   offset: 1,
            //   color: '#6A80FA'
            // }
            {
              offset: 0,
              color: '#5BAEF8'
            },
            {
              offset: 0.33,
              color: '#7A34EB'
            },
            {
              offset: 0.66,
              color: '#9D6AFC'
            },
            {
              offset: 1,
              color: '#4B56F2'
            }
          ]),
          // shadowBlur: 4,
          // shadowColor: '#000',
          // shadowOpacity: 0.8
        }
      }
    }]
  }
  return option
}
/**动效环形图 */
function dynamicPieChart({ angle, value = 88, subtext = '匹配度',  styleObj, ...other }) {
  // let angle = 0;//角度，用来做简单的动画效果的
  let option = {
    // backgroundColor:"#061740",
    // backgroundColor: "#fff",
    title: {
      // text: '{a|'+ value +'}{c|%}',
      // text: value + '分',
      text: '{a|'+ value +'}{c|' + other.unit + '}',
      x: 'center',
      y: 'center',
      textStyle: {
        rich:{
          a: {
            fontSize: 30,
            color: '#333',
            position: 'absolute'
            // verticalAlign: 'text-bottom'
          },
          b: {
            fontSize: 40,
            color: '#000'
          },
          c: {
            fontSize: 14,
            color: '#666',
            position: 'absolute',
            top: '4px'
            // verticalAlign: 'text-bottom'
          }
        }
      },
      // textStyle: {
      //   color: '#333',
      //   fontSize: 30
      // },
      subtext: subtext,
      subtextStyle: {
        color: '#666666',
        fontSize: '100%',
        fontWeight: 400
      },
      itemGap: 20,
      left: 'center',
      top: '40%'
    },
    legend: {
      type: "plain",
      orient: "vertical",
      right: 0,
      top: "10%",
      align: "auto",
      data: [{
          name: '涨价后没吃过',
          icon: "circle"
      }, {
          name: '天天吃',
          icon: "circle"
      }, {
          name: '三五天吃一次',
          icon: "circle"
      }, {
          name: '半个月吃一次',
          icon: "circle"
      }],
      textStyle: {
        color: "#333",
        fontSize: 16,
        padding: [10, 1, 10, 0]
      },
      selectedMode:false
    },
    series: [
      {
        name: "ring5",
        type: 'custom',
        coordinateSystem: "none",
        renderItem: function(params, api) {
          return {
            type: 'arc',
            shape: {
              cx: api.getWidth() / 2,
              cy: api.getHeight() / 2,
              r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.9,
              startAngle: (0 + angle) * Math.PI / 180,
              endAngle: (90 + angle) * Math.PI / 180
            },
            style: {
              stroke: styleObj.mainColor,
              fill: "transparent",
              lineWidth: 1.5
            },
            silent: true
          }
        },
        data: [0]
      },
      {
        name: "ring5",
        type: 'custom',
        coordinateSystem: "none",
        renderItem: function(params, api) {
          return {
            type: 'arc',
            shape: {
              cx: api.getWidth() / 2,
              cy: api.getHeight() / 2,
              r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.9,
              startAngle: (180+angle) * Math.PI / 180,
              endAngle: (270+angle) * Math.PI / 180
            },
            style: {
              stroke: styleObj.mainColor,
              fill: "transparent",
              lineWidth: 1.5
            },
            silent: true
          }
        },
        data: [0]
      },
      {
        name: "ring5",
        type: 'custom',
        coordinateSystem: "none",
        renderItem: function(params, api) {
          return {
              type: 'arc',
              shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.95,
                  startAngle: (270+-angle) * Math.PI / 180,
                  endAngle: (40+-angle) * Math.PI / 180
              },
              style: {
                stroke: styleObj.mainColor,
                fill: "transparent",
                lineWidth: 1.5
              },
              silent: true
          };
        },
        data: [0]
      },
      {
        name: "ring5",
        type: 'custom',
        coordinateSystem: "none",
        renderItem: function(params, api) {
          return {
            type: 'arc',
            shape: {
              cx: api.getWidth() / 2,
              cy: api.getHeight() / 2,
              r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.95,
              startAngle: (90+-angle) * Math.PI / 180,
              endAngle: (220+-angle) * Math.PI / 180
            },
            style: {
              stroke: styleObj.mainColor,
              fill: "transparent",
              lineWidth: 1.5
            },
            silent: true
          }
        },
        data: [0]
      },
      {
        name: "ring5",
        type: 'custom',
        coordinateSystem: "none",
        renderItem: function(params, api) {
          let x0 = api.getWidth() / 2;
          let y0 = api.getHeight() / 2;
          let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.95;
          let point = getCirlPoint(x0, y0, r, (90+-angle))
          return {
            type: 'circle',
            shape: {
              cx: point.x,
              cy: point.y,
              r: 4
            },
            style: {
              stroke: styleObj.mainColor,//粉
              fill: styleObj.mainColor
            },
            silent: true
          };
        },
        data: [0]
      },
      {
        name: "ring5",  // 圆点
        type: 'custom',
        coordinateSystem: "none",
        renderItem: function(params, api) {
          let x0 = api.getWidth() / 2;
          let y0 = api.getHeight() / 2;
          let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.95;
          let point = getCirlPoint(x0, y0, r, (270+-angle))
          return {
            type: 'circle',
            shape: {
              cx: point.x,
              cy: point.y,
              r: 4
            },
            style: {
              stroke: styleObj.mainColor,      //绿
              fill: styleObj.mainColor
            },
            silent: true
          }
        },
        data: [0]
      },
      {
        name: '吃猪肉频率',
        type: 'pie',
        radius: ['80%', '65%'],
        // radius: ['58%', '45%'],
        silent: true,
        clockwise: true,
        startAngle: 270,
        z: 0,
        zlevel: 0,
        label: {
          normal: {
            position: "center"
          }
        },
        data: [
          {
            value: value,
            name: "",
            itemStyle: {
              normal: {
                color: { // 完成的圆环的颜色
                  colorStops: [
                    // {
                    //   offset: 0,
                    //   color: '#5BAEF8' // 0% 处的颜色
                    // },
                    // {
                    //   offset: 0.33,
                    //   color: '#7A34EB' // 0% 处的颜色
                    // },
                    // {
                    //   offset: 0.66,
                    //   color: '#9D6AFC' // 100% 处的颜色
                    // },
                    // {
                    //   offset: 1,
                    //   color: '#4B56F2' // 100% 处的颜色
                    // }
                    {
                      offset: 0,
                      color: styleObj.color[0]
                    },
                    {
                      offset: 1,
                      color: styleObj.color[1]
                    }
                  ]
                }
                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ 
              //   offset: 0,
              //   color: '#5BAEF8'
              //   },
              //   {
              //     offset: .34,
              //     color: '#7A34EB'
              //   },
              //   {
              //     offset: 1,
              //     color: '#4B56F2'
              //   }
              // ])
                // color: '#4B56F2'
              }
            }
          },
          {
            value: 100 - value,
            name: "",
            label: {
              normal: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                // color: "#173164"
                color: "#E6E6F3"
              }
            }
          }
        ]
      },
      {
        name: "",
        type: "gauge",
        radius: "100%",
        center: ['50%', '50%'],
        startAngle: 0,
        endAngle: 359.9,
        splitNumber: 8,
        hoverAnimation: true,
        axisTick: {
          show: false
        },
        splitLine: {
          length: 60,
          lineStyle: {
            width: 0,
            // color: "#061740"
            color: 'pink'
          }
        },
        axisLabel: {
          show: false
        },
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            opacity: 0
          }
        },
        detail: {
          show: false
        },
        data: [{
          value: 0,
          name: ""
        }]
      }
    ]
  }
  return option
}
function dChart(angle) {
  // let angle = 0;//角度，用来做简单的动画效果的
let value = 55.33;
let option = {
backgroundColor:"#061740",
title: {
        text: '{a|'+ value +'}{c|%}',
        x: 'center',
        y: 'center',
        textStyle: {
            rich:{
                a: {
                    fontSize: 48,
                    color: '#29EEF3'
                },
                
                c: {
                    fontSize: 20,
                    color: '#ffffff',
                    // padding: [5,0]
                }
            }
        }
    },
    legend: {
        type: "plain",
        orient: "vertical",
        right: 0,
        top: "10%",
        align: "auto",
        data: [{
            name: '涨价后没吃过',
            icon: "circle"
        }, {
            name: '天天吃',
            icon: "circle"
        }, {
            name: '三五天吃一次',
            icon: "circle"
        }, {
            name: '半个月吃一次',
            icon: "circle"
        }],
        textStyle: {
            color: "white",
            fontSize: 16,
            padding: [10, 1, 10, 0]
        },
        selectedMode:false
    },
    series: [ {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
                return {
                    type: 'arc',
                    shape: {
                        cx: api.getWidth() / 2,
                        cy: api.getHeight() / 2,
                        r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.6,
                        startAngle: (0+angle) * Math.PI / 180,
                        endAngle: (90+angle) * Math.PI / 180
                    },
                    style: {
                        stroke: "#0CD3DB",
                        fill: "transparent",
                        lineWidth: 1.5
                    },
                    silent: true
                };
            },
            data: [0]
        }, {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
                return {
                    type: 'arc',
                    shape: {
                        cx: api.getWidth() / 2,
                        cy: api.getHeight() / 2,
                        r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.6,
                        startAngle: (180+angle) * Math.PI / 180,
                        endAngle: (270+angle) * Math.PI / 180
                    },
                    style: {
                        stroke: "#0CD3DB",
                        fill: "transparent",
                        lineWidth: 1.5
                    },
                    silent: true
                };
            },
            data: [0]
        }, {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
                return {
                    type: 'arc',
                    shape: {
                        cx: api.getWidth() / 2,
                        cy: api.getHeight() / 2,
                        r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.65,
                        startAngle: (270+-angle) * Math.PI / 180,
                        endAngle: (40+-angle) * Math.PI / 180
                    },
                    style: {
                        stroke: "#0CD3DB",
                        fill: "transparent",
                        lineWidth: 1.5
                    },
                    silent: true
                };
            },
            data: [0]
        }, {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
                return {
                    type: 'arc',
                    shape: {
                        cx: api.getWidth() / 2,
                        cy: api.getHeight() / 2,
                        r: Math.min(api.getWidth(), api.getHeight()) / 2 * 0.65,
                        startAngle: (90+-angle) * Math.PI / 180,
                        endAngle: (220+-angle) * Math.PI / 180
                    },
                    style: {
                        stroke: "#0CD3DB",
                        fill: "transparent",
                        lineWidth: 1.5
                    },
                    silent: true
                };
            },
            data: [0]
        }, {
            name: "ring5",
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
                let x0 = api.getWidth() / 2;
                let y0 = api.getHeight() / 2;
                let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.65;
                let point = getCirlPoint(x0, y0, r, (90+-angle))
                return {
                    type: 'circle',
                    shape: {
                        cx: point.x,
                        cy: point.y,
                        r: 4
                    },
                    style: {
                        stroke: "#0CD3DB",//粉
                        fill: "#0CD3DB"
                    },
                    silent: true
                };
            },
            data: [0]
        }, {
            name: "ring5",  //绿点
            type: 'custom',
            coordinateSystem: "none",
            renderItem: function(params, api) {
                let x0 = api.getWidth() / 2;
                let y0 = api.getHeight() / 2;
                let r = Math.min(api.getWidth(), api.getHeight()) / 2 * 0.65;
                let point = getCirlPoint(x0, y0, r, (270+-angle))
                return {
                    type: 'circle',
                    shape: {
                        cx: point.x,
                        cy: point.y,
                        r: 4
                    },
                    style: {
                        stroke: "#0CD3DB",      //绿
                        fill: "#0CD3DB"
                    },
                    silent: true
                };
            },
            data: [0]
        }, {
            name: '吃猪肉频率',
            type: 'pie',
            radius: ['58%', '45%'],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
                normal: {
                    position: "center",

                }
            },
            data: [{
                    value: value,
                    name: "",
                    itemStyle: {
                        normal: {
                            color: { // 完成的圆环的颜色
                                colorStops: [{
                                    offset: 0,
                                    color: '#4FADFD' // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#28E8FA' // 100% 处的颜色
                                }]
                            },
                        }
                    }
                },
                {
                    value: 100-value,
                    name: "",
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: "#173164"
                        }
                    }
                }
            ]
        },
        
        {
            name: "",
            type: "gauge",
            radius: "58%",
            center: ['50%', '50%'],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 8,
            hoverAnimation: true,
            axisTick: {
                show: false
            },
            splitLine: {
                length: 60,
                lineStyle: {
                    width: 5,
                    color: "#061740"
                }
            },
            axisLabel: {
                show: false
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    opacity: 0
                }
            },
            detail: {
                show: false
            },
            data: [{
                value: 0,
                name: ""
            }]
        },
        
    ]
};
return option
}

//获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
function getCirlPoint(x0, y0, r, angle) {
  let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
  let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
  return {
    x: x1,
    y: y1
  }
}
// 雷达图
function radarChart(valueData = [60, 73, 85, 40], radius = '75%') {
  let indicatorText = [
    {text: '安全性', max: 100, axisLabel: {
      show: true
    }},
    {text: '准确性', max: 100},
    {text: '匹配性', max: 100},
    {text: '完整性', max: 100}
  ]
  let finalData = []
  for (let i = 0; i < indicatorText.length; i++) {
    let item = indicatorText[i]
    finalData.push({
      name: item.text,
      value: valueData[i]
    })
  }
  let option = {
    title: {
      text: '多雷达图',
      show: false
    },
    tooltip: {
      transitionDuration: 0,
      trigger: 'axis',
      position: ['40px', '20%'],
      textStyle: {
        color: '#f3f3f3'
      },
      extraCssText:'width:120px;height:120px;background: linear-gradient(0deg, #5048F1 0%, #6A80FA 100%)',
      show: true,
    //   formatter: function(params) { //自定义函数修改折线图给数据加单位
    //     console.log(params)
    //     var str = '' //声明一个变量用来存储数据
    //     str += '<div>'+ params.name +'</div>'   //显示日期的函数
    //     str += `<div></div>`
    //     console.log(str)
    //     return str
    //  }
    },
    legend: {
      left: 'center',
      data: ['某软件'],
      show: false
    },
    radar: [
      {
        indicator: indicatorText,
        name: {
          textStyle: {
            // color: '#fff',
            // backgroundColor: '#999',
            // borderRadius: 3,
            fontSize: 12,
            // padding: [-5, -5]
          },
          formatter: function(value) {
            let list = value.split("")
            let result = ""
            for(let i = 1; i <= list.length; i++) {
              if(!(i % 2) && list[i] !== undefined) {
                result += list[i-1] + '\n'
              } else {
                result += list[i-1]
              }
            }
            return result
          }
        },
        center: ['50%', '50%'],
        shape: 'circle',
        radius,
        nameGap : 5, // 图中工艺等字距离图的距离
      },
      /* {
        indicator: (function (){
            var res = [];
            for (var i = 1; i <= 12; i++) {
                res.push({text: i + '月', max: 100});
            }
            return res;
        })(),
        center: ['75%', '40%'],
        radius: 80
      } */
    ],
    series: [
      {
        type: 'radar',
        tooltip: {
          trigger: 'item'
        },
        areaStyle: {
          normal: {
            color: '#3166f7',
            opacity: 0.3 // 图形透明度
          }
        },
        itemStyle: { // 单个拐点标志的样式设置。
          normal: {
            borderColor: '#2B60F8',
            // 拐点的描边颜色。[ default: '#000' ]
            borderWidth: 5,
            // 拐点的描边宽度，默认不描边。[ default: 0 ]
          }
        },
        lineStyle: { // 单项线条样式。
          normal: {
            color: '#2B60F8',
            opacity: 1 // 图形透明度
          }
        },
        data: [
          {
            value: valueData,
            name: '雷达分析'
          }
        ]
      }
    ]
  }
  return option
}
// 饼图(治理报告)
function createPieChart(chartPie, color, legendData, seriesData, isRing = false) {
    let radius = '70%'
    if (isRing) {
      // 环形图
      radius = ['50%', '50%'] //饼图的半径，第一个为内半径，第二个为外半径
    }
    // let pie = echarts.init(document.getElementById(chart))
    if ((typeof chartPie === 'string' && chartPie.constructor==String)) {
      chartPie = echarts.init(document.getElementById(chartPie))
    }
    let option = {
      backgroundColor: '#fff',
      title: {
        show: false,
        text: '销售量统计',
        x: 'center'
      },
      color,
      tooltip: {
        trigger: 'item',
        backgroundColor: '#fff',
        extraCssText: 'box-shadow: 0px 0px 12px 0px rgba(110, 110, 110, 0.6); border-radius: 4px;',
        textStyle: {
          color: '#333'
        },
      formatter: "{a} <br/>{b}  {c} ({d}%)"
    },
    legend: {
      show: false,
      orient: 'vertical',
      top: 'center',
      right: '25%',
      data: legendData
    },
    series: [{
      // name: '星期',
      name: '匹配情况',
      type: 'pie',
      radius,
      center: ['50%', '50%'],
      data: seriesData,
      label: {
        show: true,
        position: 'inside',
        formatter: function (p) {   //指示线对应文字,百分比
          return p.percent + "%"
        }
      },
      itemStyle: {
        borderWidth: 1, //设置border的宽度有多大
        borderColor:'#fff',
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          backgroundColor: '#fff',
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
    }]
  }
  chartPie.setOption(option)
  chartPie.on('click', function(params) {
    console.log(params.name, params.value, params.percent, params.seriesName, params.seriesType)
  })
  window.onresize = function () {
    chartPie.resize()
  }
}
// 柱状图
export {
  dynamicPieChart,
  radarChart, // 雷达图
  dChart,
  buildPieChart,
  createPieChart,
  buildReportPieChart
}
